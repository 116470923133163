// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookmarks-js": () => import("./../../../src/pages/bookmarks.js" /* webpackChunkName: "component---src-pages-bookmarks-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-detail-js": () => import("./../../../src/pages/detail.js" /* webpackChunkName: "component---src-pages-detail-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

